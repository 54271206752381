import * as React from 'react';
import './Styles/styles.css';
import { Routess } from './Components/Routes';


function App() {
 
  return (
    <section className="app">
      <Routess></Routess>
    </section>
  );
}

export default App;
